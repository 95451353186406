import produce from "immer";
import {
  ADD_TEAM,
  ADD_RECRUITMENT_SOURCE,
  ADD_OPERATION_MEMBER_CATEGORIZATION,
  ADD_LOCATION_SOURCE,
  ADD_DESIGNATION,
  ADD_STAFF_ROLE,
  ADD_MANAGER,
  ADD_MARITAL_STATUS,
  ADD_BLOOD_GROUP,
  ADD_YEARLIST,
  ADD_FULL_PROFILE,
  RESET_TEAM,
  RESET_DESIGNATION,
  RESET_STAFF_ROLE,
  RESET_MANAGER,
  RESET_MARITAL_STATUS,
  RESET_BLOOD_GROUP,
  RESET_YEARLIST,
  RESET_FULL_PROFILE,
  ADD_DEPARTMENT,
  ADD_GRADE,
  ADD_MODE_OF_WORK_SOURCE,
} from "./constants";
export const INITIAL_STATE = {
  team: [],
  designation: [],
  recruitmentSource : [],
  operation_member_categorization : [],
  locationSource : [],
  modeOfWorkSource : [],
  staffrole: [],
  manager: [],
  maritalstatus: [],
  bloodgroup: [],
  department: [],
  grade: [],
  yearlist: [],
  fullprofile: "",
};
const handleAddActions = (draft, action) => {
  switch (action.type) {
    case ADD_TEAM:
      draft.team = [...action.team];
      break;
    case ADD_RECRUITMENT_SOURCE:
      draft.recruitmentSource = [...action.recruitmentSource];
      break;
    case ADD_LOCATION_SOURCE:
      draft.locationSource = [...action.locationSource];
      break;
    case ADD_MODE_OF_WORK_SOURCE:
      draft.modeOfWorkSource = [...action.modeOfWorkSource];
      break;
    case ADD_OPERATION_MEMBER_CATEGORIZATION:
      draft.operation_member_categorization = [...action.operation_member_categorization];
      break;
    case ADD_DESIGNATION:
      draft.designation = [...action.designation];
      break;
    case ADD_STAFF_ROLE:
      draft.staffrole = [...action.staffrole];
      break;
    case ADD_MANAGER:
      draft.manager = [...action.manager];
      break;
    case ADD_FULL_PROFILE:
      draft.fullprofile = { ...action.fullprofile };
      break;
    case ADD_MARITAL_STATUS:
      draft.maritalstatus = [...action.maritalstatus];
      break;
    case ADD_BLOOD_GROUP:
      draft.bloodgroup = [...action.bloodgroup];
      break;
    case ADD_DEPARTMENT:
      draft.department = action.department;
      break;
    case ADD_GRADE:
      draft.grade = action.grade;
      break;
    case ADD_YEARLIST:
      draft.yearlist = [...action.yearlist];
      break;
    default:
      break;
  }
};

const handleResetActions = (draft, action) => {
  switch (action.type) {
    case RESET_TEAM:
      draft.team = [];
      break;
    case RESET_DESIGNATION:
      draft.designation = [];
      break;
    case RESET_STAFF_ROLE:
      draft.staffrole = [];
      break;
    case RESET_MANAGER:
      draft.manager = [];
      break;
    case RESET_MARITAL_STATUS:
      draft.maritalstatus = [];
      break;
    case RESET_BLOOD_GROUP:
      draft.bloodgroup = [];
      break;
    case RESET_YEARLIST:
      draft.yearlist = [];
      break;
    case RESET_FULL_PROFILE:
      draft.fullprofile = "";
      break;
    default:
      break;
  }
};

const staffProfileReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    handleAddActions(draft, action);
    handleResetActions(draft, action);
  });
};


export default staffProfileReducer;
