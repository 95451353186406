import axios from "axios";
import axiosInstace from "./axios";
import { SOMETHING_WRONG } from "./labelContant";
import { toast } from "react-toastify";
import axiosDot from "./axios";

export const uploadPresigned = async (
  file = {},
  bucket_name,
  setPercent = () => {}
) => {
  let returnObject = [];
  let formData;
  let contenType = file?.type;
  if (file?.type === "application/pdf") {
    formData = new FormData();
    formData.append("file", file);
    contenType = "multipart/form-data";
  }
  returnObject.push(
    new Promise((resolve, reject) => {
      axiosInstace
        .get(
          `/access/roles/permissions/pre-signed-url?key=${bucket_name}/${file?.name}`
        )
        .then(async (res) => {
          let url = res?.data?.result;
          await axios
            .put(url, formData ? formData : file, {
              headers: {
                "Content-type": contenType,
              },
              onUploadProgress: (e) => {
                setPercent(Math.round((e.loaded * 100) / e.total));
              },
            })
            .then((res) => {
              resolve(res);
            })
            .catch((e) => {
              toast.error("File Upload Fail", { toastId: SOMETHING_WRONG });
              reject(e);
            });
        });
    })
  );
  return await Promise.all(returnObject);
};

export const uploadPresignedMulti = async (
  file = [],bucket_name,
  setPercent = () => {}
) => {
  let array = [];
  let temp = {};
  if (Array.isArray(file)) {
    file.forEach((item, index) => {
      let formData;
      let contenType = item?.type;

      if (item?.type === "application/pdf") {
        formData = new FormData();
        contenType = "multipart/form-data";
        formData.append("file", item);
      }

      return array.push(
        new Promise((resolve, reject) => {
          axiosInstace
            .get(
              `/access/roles/permissions/pre-signed-url?key=${bucket_name}/${item?.name}`
            )
            .then(async (res) => {
              let url = res?.data?.result;
              axios
                .put(url, formData ? formData : item, {
                  headers: {
                    "Content-type": contenType,
                  },
                  onUploadProgress: (e) => {
                    temp["updPercent" + index] = Math.round(
                      (e.loaded * 100) / e.total
                    );
                    setPercent(temp);
                  },
                })
                .then((res) => {
                  resolve(res);
                })
                .catch((e) => {
                  reject(e);
                });
            });
        })
      );
    });
  }
  return Promise.all(array);
};
export const getObjectPresigned = async (file) => {
  let returnObject = [];
  if (file) {
    returnObject.push(
      await new Promise((resolve, reject) => {
        axiosDot
          .get(`/access/roles/permissions/pre-signed-obj?key=${file}`)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );
  }
  return await Promise.all(returnObject);
};
export const getURLFile = async (file) => {
  let splitKey = file.split("/");
  let presigned = await getObjectPresigned(`${splitKey[3]}/${splitKey[4]}`);
  let preDefineUrl = presigned[0]?.data?.result;
  const downloadLink = document.createElement("a");
  downloadLink.href = preDefineUrl;
  downloadLink.target = "_blank";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
export const getExportFile = async (file) => {
  let presigned = await getObjectPresigned(file);
  let preDefineUrl = presigned[0]?.data?.result;
  const downloadLink = document.createElement("a");
  downloadLink.href = preDefineUrl;
  downloadLink.target = "_blank";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export let getURL = async (file) => {
  let splitKey = decodeURI(file).split("/");
  let presigned = await getObjectPresigned(`${splitKey[3]}/${splitKey[4]}`);
  const url = presigned[0]?.data?.result;
  return url;
};
export let getInvoiceURL = async (file) => {
  let splitKey = decodeURI(file).split("/");
  let presigned = await getObjectPresigned(`${splitKey[0]}/${splitKey[1]}`);
  const url = presigned[0]?.data?.result;
  return await url;
};