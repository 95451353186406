import produce from "immer";
import {
  SET_ERROR,
  SET_PAGE_NO,
  SET_STAFF,
  RESET_ERROR,
  SET_LOADING,
  SET_STAFF_COUNT,
  SET_LOADING_STAFF,
  STAFF_SEARCH,
  STAFF_SEARCH_RESET,
  STAFF_ROLES,
} from "./constants";
export const INITIAL_STATE = {
  staff: [],
  error: "",
  pageNumber: 1,
  loading: false,
  count: 0,
  staffLoading: false,
  staffSearch: {},
  staffRoles:[]
};
const handleSearchActions = (draft, action) => {
  switch (action.type) {
    case STAFF_SEARCH: {
      const data = { ...draft.clientSearch, [action.name]: action.value };
      draft.staffSearch = data;
      break;
    }
    case STAFF_SEARCH_RESET:
      draft.staffSearch = {};
      break;
    default:
      break;
  }
};

const handleStaffActions = (draft, action) => {
  switch (action.type) {
    case SET_STAFF:
      draft.staff = action.staff;
      break;
    case SET_STAFF_COUNT:
      draft.count = action.count;
      break;
    case STAFF_ROLES:
      draft.staffRoles = [...action.roles];
      break;
    default:
      break;
  }
};

const handleLoadingActions = (draft, action) => {
  switch (action.type) {
    case SET_LOADING:
      draft.loading = action.loading;
      break;
    case SET_LOADING_STAFF:
      draft.staffLoading = action.loading;
      break;
    default:
      break;
  }
};

const handleErrorActions = (draft, action) => {
  switch (action.type) {
    case SET_ERROR:
      draft.error = action.error;
      break;
    case RESET_ERROR:
      draft.error = "";
      break;
    default:
      break;
  }
};

const staffReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    handleLoadingActions(draft, action);
    handleErrorActions(draft, action);
    handleStaffActions(draft, action);
    handleSearchActions(draft, action);
  });
};


export default staffReducer;
