import produce from "immer";
import {
  ADD_STATUS,
  ADD_JOB_TYPE,
  ADD_MANAGER,
  ADD_CLIENT,
  JOB_COUNT,
  JOB_ERROR,
  JOB_GET,
  JOB_GET_LIST_LOADING,
  JOB_GET_PAGE_NO,
  JOB_SEARCH,
  JOB_SEARCH_RESET,
  JOB_EXPORT,
} from "./constants";
export const INITIAL_STATE = {
  jobData: [],
  jobExportData: [],
  status: [],
  jobType: [],
  manager: [],
  client: [],
  jobError: "",
  pageNumber: 1,
  jobListLoading: false,
  count: 0,
  jobSearch: [],
};
const handleJobDataActions = (draft, action) => {
  switch (action.type) {
    case JOB_GET:
      draft.jobData = action.data;
      break;
    case JOB_EXPORT:
      draft.jobExportData = action.data;
      break;
    case JOB_ERROR:
      draft.error = action.error;
      break;
    case JOB_GET_LIST_LOADING:
      draft.jobListLoading = action.loading;
      break;
    case JOB_GET_PAGE_NO:
      draft.pageNumber = action.pageNumber;
      break;
    case JOB_COUNT:
      draft.count = action.count;
      break;
    default:
      break;
  }
};

const handleJobSearchActions = (draft, action) => {
  switch (action.type) {
    case JOB_SEARCH: {
      const data = { ...draft.jobSearch, [action.name]: action.value };
      draft.jobSearch = data;
      break;
    }
    case JOB_SEARCH_RESET:
      draft.jobSearch = {};
      break;
    default:
      break;
  }
};

const handleJobAdditionalActions = (draft, action) => {
  switch (action.type) {
    case ADD_STATUS:
      draft.status = [...action.status];
      break;
    case ADD_JOB_TYPE:
      draft.jobType = [...action.jobType];
      break;
    case ADD_MANAGER:
      draft.manager = [...action.manager];
      break;
    case ADD_CLIENT:
      draft.client = [...action.client];
      break;
    default:
      break;
  }
};

const jobReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    handleJobDataActions(draft, action);
    handleJobSearchActions(draft, action);
    handleJobAdditionalActions(draft, action);
  });
};


export default jobReducer;
