export const accessActionType = {
    READ:"READ",
    CREATE:"CREATE",
    UPDATE:"UPDATE",
    DELETE:"DELETE",
 };
 export const accessModule = {
 JOBS: "Jobs",
 JOBS_VIEWFEEDBACK :"Job > View Feedback",
 ADVANCE_JOB_ACCESS :"Advance Job Access",
 QUOTATION: "Quotation",
 CLIENT: "Client",
 INVOICING: "Invoicing",
 STAFF: "Staff",
 MY_ACCOUNT: "MyAccount",
 ACCESS: "Access",
 MASTER: "Master",
 REPORTS:"Reports >",
 REPORTS_CLIENT: "Reports > Client",
 REPORTS_OPERATIONS: "Reports > Operations",
 REPORTS_MANAGEMENT: "Reports > Management",
 REPORTS_HR: "Reports > HR",
 ARCHIVE_JOBS: "Archive Jobs",
 RECURRENCE_JOBS: "Recurrence Jobs",
 HR_RECRUITMENT: "HR > Recruitment",
 HR_TIMEKEEPER: "HR > Timekeeper",
 HR_TIMEKEEPER_ATTENDANCE:"HR > Timekeeper > Attendance",
 HR_TEMPLATES: "HR > Templates",
 HR_CANDIDATES: "HR > Candidates",
 HR_ONBOARDING: "HR > Onboarding",
 HR_TIMEKEEPER_LEAVES: "HR > Timekeeper > Leaves",
 STAFF_TRAINING_CREATE_TRAINING: "Staff > Training > Create Training",
 STAFF_TRAINING: "Staff > Training >",
 STAFF_TRAINING_DOT_SKILL: "Staff > Training > Dot Skill",
 STAFF_TRAINING_SKILL_LOGS: "Staff > Training > Skill Logs",
 STAFF_PERFORMANCE: "Staff > Performance Reports",
 STAFF_EXIT: "Staff > Exit",
 STAFF_EXITED_STAFF: "Staff > Exited Staff", 
 };