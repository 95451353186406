import produce from "immer";
import {
  EVENT_COUNT,
  EVENT_ERROR,
  EVENT_GET,
  EVENT_GET_LIST_LOADING,
  EVENT_GET_PAGE_NO,
  EVENT_SEARCH,
  EVENT_SEARCH_RESET,
} from "./constants";
export const INITIAL_STATE = {
  eventData: [],
  eventError: "",
  pageNumber: 1,
  eventListLoading: false,
  count: 0,
  eventSearch: {},
};
const eventReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case EVENT_GET:
        draft.eventData = action.data;
        break;
      case EVENT_ERROR:
        draft.error = action.error;
        break;
      case EVENT_GET_LIST_LOADING:
        draft.eventListLoading = action.loading;
        break;
      case EVENT_GET_PAGE_NO:
        draft.pageNumber = action.pageNumber;
        break;
      case EVENT_COUNT:
        draft.count = action.count;
        break;
      case EVENT_SEARCH:
        {const data = { ...draft.eventSearch, [action.name]: action.value };
        draft.eventSearch = data;}
        break;
      case EVENT_SEARCH_RESET:
        draft.eventSearch = {};
        break;
      default:
        return draft;
    }
  });
};

export default eventReducer;
