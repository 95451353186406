import produce from "immer";
import {
  ADD_SOURCE ,
  ADD_EVENT,
  ADD_ENGAGEMENT,
  ADD_CATEGORISATION, 
  ENQUIRY_COUNT,
  ENQUIRY_ERROR,
  ENQUIRY_GET,
  ENQUIRY_GET_LIST_LOADING,
  ENQUIRY_GET_PAGE_NO,
  ENQUIRY_SEARCH,
  ENQUIRY_SEARCH_RESET,
} from "./constants";
export const INITIAL_STATE = {
  enquiryData: [],
  source: [],
  event: [],
  engagement: [],
  categorisation: [],
  enquiryError: "",
  pageNumber: 1,
  enquiryListLoading: false,
  count: 0,
  enquirySearch: {},
};
const handleEnquiryDataActions = (draft, action) => {
  switch (action.type) {
    case ENQUIRY_GET:
      draft.enquiryData = action.data;
      break;
    case ENQUIRY_ERROR:
      draft.error = action.error;
      break;
    case ENQUIRY_GET_LIST_LOADING:
      draft.enquiryListLoading = action.loading;
      break;
    case ENQUIRY_GET_PAGE_NO:
      draft.pageNumber = action.pageNumber;
      break;
    case ENQUIRY_COUNT:
      draft.count = action.count;
      break;
    default:
      break;
  }
};

const handleEnquirySearchActions = (draft, action) => {
  switch (action.type) {
    case ENQUIRY_SEARCH: {
      const data = { ...draft.enquirySearch, [action.name]: action.value };
      draft.enquirySearch = data;
      break;
    }
    case ENQUIRY_SEARCH_RESET:
      draft.enquirySearch = {};
      break;
    default:
      break;
  }
};

const handleEnquiryAdditionalActions = (draft, action) => {
  switch (action.type) {
    case ADD_SOURCE:
      draft.source = [...action.source];
      break;
    case ADD_EVENT:
      draft.event = [...action.event];
      break;
    case ADD_ENGAGEMENT:
      draft.engagement = [...action.engagement];
      break;
    case ADD_CATEGORISATION:
      draft.categorisation = [...action.categorisation];
      break;
    default:
      break;
  }
};

const enquiryReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    handleEnquiryDataActions(draft, action);
    handleEnquirySearchActions(draft, action);
    handleEnquiryAdditionalActions(draft, action);
  });
};


export default enquiryReducer;
