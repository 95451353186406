import Snowfall from "react-snowfall";

import reindeer from "./images/reindeer.png"
import walkingSanta from "./images/walkingSanta.gif"
import bell from "./images/bell.gif"
import bell2 from "./images/bell.gif"
import snowMan from "./images/snowMan.gif"
import trees from "./images/trees.png"

import ganpatiLeftBanner from "./images/ganpatiLeftBanner.png"



import design from "./images/design.png"
import modak from "./images/modak.gif"
import tempSmall from "./images/tempSmall.png"
import tempBig from "./images/tempBig.png"
import people1 from "./images/people.png"
import people2 from "./images/people.png"
import people3 from "./images/people.png"

import jumber from "./images/jumber.png"
import leftLantern from "./images/leftLantern.gif"
import design_diwali from "./images/design-diwali.png"
import line from "./images/line.svg"
import line_horizontal from "./images/line_horizontal.png"


import line_verticle from "./images/line_verticle.svg"
import lampBottom from "./images/lampBottom.gif"



import leftSplash from "./images/leftSplash.gif"
import bottomHappyHoli from "./images/bottomHappyHoli.gif"
import rightBaloons from "./images/rightBaloons.gif"



import leftJumer from "./images/leftJumer.png"
import rightMoon from "./images/rightMoon.gif"
import leftMasjit from "./images/leftMasjit.png"
import leftName from "./images/leftName.png"
import leftStar from "./images/leftStar.gif"
import rightMasjit from "./images/rightMasjit.png"


import leftTop from "./images/leftTop.gif"
import rightEgg from "./images/rightEgg.gif"
import rightBottomEaster from "./images/rightBottomEaster.png"
import leftBottom from "./images/leftBottom.png"
import grass from "./images/grass.png"


import Navratri_new from "./images/navratri_12_59.gif"
import rightDandiya from "./images/rightDandiya.png"
import designRightNavratri from "./images/designRightNavratri.png"

import leftPeople from "./images/leftPeople.png"
import rightPeople from "./images/rightPeople.png"

import Friday from "../../styles/images/Group 2085662804.png"
import topleft from "../../styles/images/goodFridayTopLogo.png"
import GoodFriday from "../../styles/images/goodFridayLogo.png"
import GoodFridayBottom from "../../styles/images/goodFridayBottomOrange.png"
import GoodFridayBottomwhite from "../../styles/images/Clip_path_group1.png"





import { useEffect, useState } from "react";

const Theme=({theme})=>{
    console.log(theme)

    // const templateOptions = ["Christmas", "Diwali", "Holi", "Navratri", 
    // "Ganesh Chaturthi", "Ramzan", "Easter", "Good Friday"];

    const snowflake1 = document.createElement('img')
    snowflake1.src = './images/2.jpg'
    const snowflake2 = document.createElement('img')
    snowflake2.src = '/assets/3.jpg'

    const images=[snowflake1,snowflake2]

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 3000); // 3-second delay
        return () => clearTimeout(timer); // Cleanup timeout
    }, []);

    return(
        <>

            {theme==="Christmas"?
                <div className="login-new-ui chrismas-theme">
                    <img src={reindeer} alt="hi I am santa component" className="fade-in" />
                    <img
                        src={walkingSanta}
                        alt="hi I am santa component"
                        className={`delayed-appear ${isVisible ? 'visible' : ''}`}
                        style={{
                            right: "18%",
                            top: "17%",
                            width: "209px",
                            height: "212px",
                        }}
                    />
                    <img
                        src={bell}
                        alt="hi I am santa component"
                        className={`delayed-appear ${isVisible ? 'visible' : ''}`}
                        style={{
                            right: "5%",
                            top: "5%",
                            width: "130px",
                            height: "131px",
                        }}
                    />
                    <img
                        src={bell2}
                        alt="hi I am santa component"
                        className={`delayed-appear ${isVisible ? 'visible' : ''}`}
                        style={{
                            transform: "scaleX(-1)",
                            right: "9%",
                            top: "15%",
                            width: "130px",
                            height: "131px",
                        }}
                    />
                    <img
                        src={snowMan}
                        alt="hi I am santa component"
                        className="fade-in"
                        style={{
                            transform: "scaleX(-1)",
                            left: "1%",
                            bottom: "1%",
                            width: "274px",
                            height: "256px",
                            zIndex: "20",
                        }}
                    />
                    <img
                        src={trees}
                        style={{ bottom: 0, right: 5 }}
                        alt="hi I am santa component"
                        className="fade-in"
                    />
                    <Snowfall snowflakeCount={200} />
                    </div>:""}

{theme==="Diwali"?
<div className="login-new-ui diwali">
    <img src={jumber} alt="diwali image" style={{
    position:"absolute",
    top:"0",
    left:"55px",
    height:"496.64px",
    width:"219.75px", 
    }}



    />
    <img src={leftLantern} alt="diwali image" style={{
    position:"absolute",
    top:"0",
    left:"0",
    height:"258px",
    width:"258px", 
    }}
    />
    <img src={designRightNavratri} alt="diwali image" 
    className={`delayed-appear ${isVisible ? 'visible' : ''}`}
    style={{
        position:"absolute",
        top:"0",
        right:"6%",
        zIndex:"10",
        height:"300px"  
    }}
    />
    <img src={line_horizontal} alt="diwali image" style={{
        position:"absolute",
        bottom:"4%",
        opacity:"90%",
        left:"135px",

    }}
    />
    <img src={line_horizontal} alt="diwali image" style={{
        position:"absolute",
        bottom:"4.8%",
        opacity:"90%",
        left:"135px",

    }}
    />
    <img src={line_verticle} alt="diwali image" style={{
        position:"absolute",
        bottom:"9%",
        left:"40.24px",

    }}
    />
    <img src={lampBottom} alt="diwali image" style={{
        position:"absolute",
        bottom:"-102px",
        left:"-102px",
        width:"418px",
        height:"418px",

    }}
    />
</div>:""}

{theme==="Holi"?
<div className="login-new-ui holi">

<img src={leftSplash} alt="holi image" style={{
    position:"absolute",
    top:"0",
    left:"0",
    width:"270px",
    height:"220px",               
}}
/>
<img src={leftSplash} alt="holi image" 
className={`delayed-appear ${isVisible ? 'visible' : ''}`}
style={{
    position:"absolute",
    top:"40px",
    right:"0",
    width:"270px",
    height:"220px",               
}}
/>
<img src={rightBaloons} alt="holi image" 
className={`delayed-appear ${isVisible ? 'visible' : ''}`}
style={{
    position:"absolute",
    top:"123px",
    right:"10%",
    width:"227.79px",
    
}}
/>
<img src={bottomHappyHoli} alt="holi image" style={{
    position:"absolute",
    bottom:"0",
    left:"13px",
    width:"227.79px",
    
}}
/>




</div>:""}

{theme==="Navratri"?
<div className="login-new-ui navratri">
    <img src={Navratri_new} alt="holi image" style={{
        position:"absolute",
        width:"396px",
        height:"396px",
        top:"-5%",
        left:"-1px",                                
    }}
    />
    <img src={rightDandiya} alt="holi image" 
    className={`delayed-appear ${isVisible ? 'visible' : ''}`}
    style={{
        position:"absolute",
        width:"333.93px",
        height:"375px",
        top:"60px",
        right:"20px",
        zIndex:"20",
    }}
    />
    <img src={designRightNavratri} alt="holi image"
    className={`delayed-appear ${isVisible ? 'visible' : ''}`}
    style={{
        position:"absolute",
        height:"300px",
        top:"0",
        right:"6%",
    }}
    />
    <img src={rightPeople} alt="holi image" style={{
        position:"absolute",
        height:"110.9px",
        width:"435.95px",
        bottom:"-20px",
        right:"0",
    }}
    />
    <img src={leftPeople} alt="holi image" style={{
        position:"absolute",
        height:"110.9px",
        width:"690.43px",
        bottom:"-20px",
        right:"31%",
    }}
    />
    <img src={tempBig} alt="ganapati image" style={{
        width:"144.98px",
        height:"433.21px",
        bottom:"-5%",
        left:"0",
    }}
    />
    <img src={tempSmall} alt="ganapati image" style={{
        width:"134.45px",
        height:"297.68px",
        bottom:"0%",
        left:"10%",
    }}
    />


</div>:""}

{theme==="Ganesh Chaturthi"?
<div className="login-new-ui ganesh-chaturthi" >

        <img src={ganpatiLeftBanner} alt="ganapati image" style={{
            position:"absolute",
            top:"0",
            left:"0",
            height:"354px",
            width:"354px", 
        }}
        
        
        
        />


        <img src={design} alt="ganapati image"
        className={`delayed-appear ${isVisible ? 'visible' : ''}`}
        style={{
            position:"absolute",
            top:"0",
            right:"6%",
            
            height:"300px",
            
        }}
        />


        <img src={modak} alt="ganapati image" 
        className={`delayed-appear ${isVisible ? 'visible' : ''}`}
        style={{
            width:"185px",
            height:"185px",
            top:"15%",
            right:"15%"
        }}/>
        <img src={tempBig} alt="ganapati image" style={{
            width:"185px",
            height:"554px",
            bottom:"0",
            right:"0",
        }}
        
        />
        <img src={tempSmall} alt="ganapati image" style={{
            width:"172px",
            height:"380px",
            bottom:"0%",
            right:"11%",
        }}
        
        />




        <img src={people1} alt="ganapati image" style={{
            width:"350px",
            height:"268px",
            bottom:"0%",
            left:"0",
        }}
        
        />
        <img src={people2} alt="ganapati image" style={{
            width:"350px",
            height:"268px",
            bottom:"-7%",
            left:"326px",
        }}
        
    />
    <img src={people3} alt="ganapati image" style={{
        width:"350px",
        height:"268px",
        bottom:"-14%",
        left:"672px",
    }}
    
    
    />

</div>:""}

{theme==="Ramzan"?
<div className="login-new-ui ramzan">

<img src={leftJumer} alt="holi image" style={{
    position:"absolute",
    width:"700px",
    height:"140px",
    top:"",
    Left:"-11px",
                  
}}
/>
<img src={rightMoon} alt="holi image" 
className={`delayed-appear ${isVisible ? 'visible' : ''}`}
style={{
    position:"absolute",
    width:"150px",
    height:"150px",
    right:"60px"
                  
}}
/>
<img src={leftMasjit} alt="holi image" style={{
    position:"absolute",
    width:"455px",
    height:"374px",
    bottom:"20px",
    left:"8px", 
}}
/>
<img src={leftName} alt="holi image" style={{
    position:"absolute",
    width:"151.2px",
    height:"51px",
    bottom:"20px",
    left:"276px", 
}}
/>
<img src={rightMasjit} alt="holi image" style={{
    position:"absolute",
    width:"480px",
    height:"412.96px",
    bottom:"0",
    right:"0",            
}}
/>

<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"50px",
    height:"50px",
    bottom:"134px",
    left:"50px",
    opacity:"50%"
}}
/>
<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"40px",
    height:"40px",
    bottom:"210px",
    left:"40px",
    opacity:"50%"
}}
/>
<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"20px",
    height:"20px",
    bottom:"210px",
    left:"90px",
    opacity:"50%"
}}
/>

<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"50px",
    height:"50px",
    bottom:"350px",
    left:"174.15px",
    transform: "rotate(90deg)",
    opacity:"50%"
}}
/>
<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"40px",
    height:"40px",
    bottom:"370px",
    left:"250.33px",
    transform: "rotate(90deg)",
    opacity:"50%"
}}
/>
<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"50px",
    height:"50px",
    bottom:"289px",
    left:"350.85px",
    transform: "rotate(144.79deg)",
    opacity:"50%"
}}
/>
<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"40px",
    height:"40px",
    bottom:"230px",
    left:"415.15px",
    transform: "rotate(144.79deg)",
    opacity:"50%"
}}
/>
<img src={leftStar} alt="holi image" style={{
    position:"absolute",
    width:"20px",
    height:"20px",
    bottom:"230px",
    left:"395.84px",
    transform: "rotate(144.79deg)",
    opacity:"50%"
}}
/>

</div>:""}

{theme==="Easter"?
<div className="login-new-ui easter">
<img src={leftTop} alt="holi image" style={{
    position:"absolute",
    width:"300px",
    height:"300px",
    top:"-30px",
    left:"0",
    transform:"rotate(-27.22deg)",
                
}}
/>
<img src={rightEgg} alt="holi image"
className={`delayed-appear ${isVisible ? 'visible' : ''}`}
style={{
    position:"absolute",
    width:"120px",
    height:"120px",
    top:"40.97px",
    left:"671px",
    transform:"rotate(-18.95deg)",          
}}
/>
<img src={rightBottomEaster} alt="holi image" style={{
    position:"absolute",
    width:"377px",
    height:"377px",
    bottom:"20px",
    right:"0",
    zIndex:"20",    
}}
/>

<img src={leftBottom} alt="holi image" style={{
    position:"absolute",
    width:"389.44px",
    height:"186.86px",
    bottom:"0",
    Left:"0",          
}}
/>
<img src={grass} alt="holi image" style={{
    position:"absolute",
    width:"80%",
    opacity:"50%",
    bottom:"0",
    right:"0",          
}}
/>

</div>:""}
            {theme === "Good Friday" && (
                <div className="login-new-ui">
                    <img
                        src={topleft}
                        alt="Top Left Decoration"
                        className="top-left"
                    />
                    <img
                        src={topleft}
                        alt="Top Left Decoration Duplicate"
                        className="top-left-duplicate"
                    />
                    <img
                        src={GoodFriday}
                        alt="Good Friday Symbol"
                        className="good-friday-symbol"
                    />
                    <img
                        src={Friday}
                        alt="Friday Symbol"
                        className="friday-symbol"
                    />
                    <img
                        src={GoodFridayBottom}
                        alt="Good Friday Background"
                        className="good-friday-background"
                    />
                    <img
                        src={GoodFridayBottom}
                        alt="Good Friday Background Overlay"
                        className="good-friday-overlay"
                    />
                    <img
                        src={GoodFridayBottomwhite}
                        alt="White Good Friday Background"
                        className="good-friday-white-background"
                    />
                </div>
            )}


        </>
    )

}
export default Theme;